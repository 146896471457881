import { Button } from '@andes/button';
import { Card as AndesCard } from '@andes/card';
import { Modal } from '@andes/modal';
import Spinner from '@andes/spinner';
import classnames from 'classnames';
import { bool, object, shape, string } from 'prop-types';
import React, { useState } from 'react';

import {
  STATIC_ICON,
  STATIC_ILLUSTRATION,
} from '../../../../../../utils/constants';
import { trackEvent } from '../../../../../services/tracking';
import IconCategories from '../../../icons/dynamic-access/categories';
import IconCellphoneCategory from '../../../icons/dynamic-access/cellphones-category';
import IconHelp from '../../../icons/dynamic-access/help';
import IconLocation from '../../../icons/dynamic-access/location';
import IconLowPriceproducts from '../../../icons/dynamic-access/low-price-product';
import IconMlbLowPriceproducts from '../../../icons/dynamic-access/mlb-low-price-product';
import IconMpeLowPriceproducts from '../../../icons/dynamic-access/mpe-low-price-products';
import IconNewBuyer from '../../../icons/dynamic-access/new-buyers';
import IconOfficialStore from '../../../icons/dynamic-access/official-store';
import IconPeymentMethods from '../../../icons/dynamic-access/payment-methods';
import IconProtectedBuy from '../../../icons/dynamic-access/protected-buy';
import IconPregrstration from '../../../icons/dynamic-access/registration';
import IconSellWithUs from '../../../icons/dynamic-access/sell-with-us';
import IconShoesCategory from '../../../icons/dynamic-access/shoes-category';
import IconTopSales from '../../../icons/dynamic-access/top-sale';
import IconFactoryFunction from '../../../icons/factory-builder';
import Iframe from '../../../iframe';
import withTracker from '../../../with-tracker';
import withTrackerOnPrint from '../../../with-tracker-on-print';
import CustomAction from '../../utils/custom-action';

const IconFactory = IconFactoryFunction([
  IconLocation,
  IconLowPriceproducts,
  IconMlbLowPriceproducts,
  IconProtectedBuy,
  IconOfficialStore,
  IconCategories,
  IconPeymentMethods,
  IconPregrstration,
  IconTopSales,
  IconHelp,
  IconShoesCategory,
  IconCellphoneCategory,
  IconMpeLowPriceproducts,
  IconSellWithUs,
  IconNewBuyer,
]);

const namespace = 'dynamic-access-card';

const CardIcon = ({ card, track, type, sizeCard, isCardModal, isCustomCard }) => {
  const {
    title,
    picture,
    description,
    action,
    modal = {},
    message = '',
  } = card;
  const { label, target } = action;
  const {
    title_modal: titleModal,
    text_close: textClose,
    target_modal: targetModal,
  } = modal;


  const handleClick = () => {
    trackEvent(track);
  };
  const cardName = `${namespace}-ecosistemic`;
  const actionProps = {
    className: `${cardName}__action`,
    onClick: handleClick,
  };

  const [isOpen, setisOpen] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [iframeHeight, setIframeHeight] = useState('100%');

  /* istanbul ignore next */
  const openModal = (e) => {
    if (!e.ctrlKey && !e.metaKey) {
      e.preventDefault();
      setisOpen(true);
      setisLoading(true);
    } else {
      window.open(target, '_blank');
    }
  };

  /* istanbul ignore next */
  const onCloseModal = () => {
    setisOpen(false);
  };

  /* istanbul ignore next */
  const onIframeLoad = () => {
    setisLoading(false);
  };

  /* istanbul ignore next */
  const onMessage = (messageType, data) => {
    if (messageType === 'resize') {
      setIframeHeight(data.height);
      const modalScroll = document.querySelector('.andes-modal__scroll');
      modalScroll.scrollTo({
        top: 0,
        behavior: 'instant',
      });
    }
  };

  return (
    <AndesCard
      className={classnames(namespace, 'dynamic-access-card-common', `${namespace}__${sizeCard}`, {
        'dynamic-access-card-icon': type === STATIC_ICON,
        'dynamic-access-card-ilustrator': type === STATIC_ILLUSTRATION,
      })}
    >
      <div className={cardName}>
        <div className={`${cardName}-header`}>
          {title && <h2 className={`${cardName}__title`}>{title.text}</h2>}
        </div>
        {picture && (
          <div className={`${cardName}-icon`} aria-hidden="true">
            {IconFactory(picture.id, { deviceType: 'desktop', alt: title?.text })}
          </div>
        )}
        {description && (
          <div className={`${cardName}-description`}>
            <span className={`${cardName}__description`}>
              {description.text}
            </span>
          </div>
        )}
        <div className={`${cardName}-footer`}>
          {!isCardModal ? (
            <CustomAction
              cardName={cardName}
              target={target}
              label={label.text}
              isCustomCard={isCustomCard}
              message={message}
              actionProps={actionProps}
              accesibilityText={title?.text}
            />
          ) : (
            <>
              <Button
                hierarchy="transparent"
                size="small"
                fullWidth
                label={label.text}
                className={`${cardName}__action`}
                onClick={openModal}
              >
                {label.text}
                {title && <span className="andes-visually-hidden">{title.text}</span>}
              </Button>
              <Modal
                open={isOpen}
                onClose={onCloseModal}
                type="large"
                maxWidth={840}
                title={titleModal}
                className="modal"
                closeButtonSrLabel={textClose}
                url="#show-modal"
              >
                <Spinner modifier="fullscreen" size="large" show={isLoading} />
                <Iframe
                  title={titleModal}
                  src={targetModal}
                  onLoad={onIframeLoad}
                  onMessage={onMessage}
                  height={iframeHeight}
                  scrolling="no"
                />
              </Modal>
            </>
          )}
        </div>
      </div>
    </AndesCard>
  );
};

CardIcon.propTypes = {
  card: shape({
    content: object,
  }),
  track: object,
  type: string,
  sizeCard: string,
  isCardModal: bool,
  message: string,
  isCustomCard: bool,
};

export default withTracker(withTrackerOnPrint(CardIcon));
