import React from 'react';
import classnames from 'classnames';
import { shape, string, bool, object, number, arrayOf } from 'prop-types';
import { Image } from 'nordic/image';
import { MoneyAmountCombo } from '@andes/money-amount';
import { Card as AndesCard } from '@andes/card';

import Action from '../../../action';
import withTracker from '../../../with-tracker';
import withTrackerOnPrint from '../../../with-tracker-on-print';
import { trackEvent } from '../../../../../services/tracking';
import interpolator from '../../../../../lib/interpolator';
import StyledLabel from '../../../styled-label';
import modelMoneyAmountCombo from '../../utils/model-money-amount-combo';
import { LAZYLOAD_OFF, LAZYLOAD_ON, ALL_ITEMS_IMAGES } from '../../../../../../utils/constants';

const namespace = 'dynamic-access-card-item';

const CardItem = ({ card, track, sizeCard, index }) => {
  const {
    header,
    picture,
    name,
    permalink,
    price = {},
    shipping = {},
    action,
    tag,
    attributes,
    items_images,
    additional_items,
  } = card;
  const { original_price, current_value, discount } = price;
  const { accessibility_text: accessibilityText } = shipping;

  const hasDescriptionComponents = action || discount || (shipping && shipping.text);

  const handleClick = () => {
    trackEvent(track);
  };
  const itemsImagesLength = items_images
  && items_images.length <= ALL_ITEMS_IMAGES ? items_images.length : ALL_ITEMS_IMAGES;

  return (
    <AndesCard
      className={classnames(
        'dynamic-access-card',
        `dynamic-access-card__${sizeCard}`,
        namespace,
      )}
    >
      {header && (
        <div className={`${namespace}-header`}>
          <h2 className={`${namespace}__title`}>{header.text}</h2>
        </div>
      )}
      {items_images && (
        <div className={`${namespace}__items-images ${namespace}__items-images--${itemsImagesLength}`}>
          {items_images.slice(0, 4).map((key, i) => (i !== ALL_ITEMS_IMAGES - 1
            ? (
              <div className={`${namespace}__items-images-container`} key={key.picture.id}>
                <Image
                  src={key.picture.src}
                  alt={key.picture.alt}
                  width="100%"
                  height="100%"
                  lazyload={index < 6 ? LAZYLOAD_OFF : LAZYLOAD_ON}
                  preload
                />
              </div>
            ) : (
              <div
                className={`${namespace}__items-images--last-image ${additional_items
                  ? `${namespace}__items-images--additional_items` : ''}`}
                key={key.picture.id}
              >
                <Image
                  src={key.picture.src}
                  alt={key.picture.alt}
                  width="100%"
                  height="100%"
                  lazyload={index < 6 ? LAZYLOAD_OFF : LAZYLOAD_ON}
                  preload
                />
                {additional_items > 0 && (
                  <span className={`${namespace}__items-images__label`}>
                    +{additional_items}
                  </span>
                )}
              </div>)))}
        </div>
      )}
      {!items_images && (
        <>
          {picture && (
            <div
              className={classnames(`${namespace}__image`, {
                [`${namespace}__image--${picture.format}`]: picture.format })}
              aria-hidden="true"
            >
              <Image
                src={picture.src}
                width="100%"
                height="100%"
                alt={name.text}
                lazyload={LAZYLOAD_OFF}
                preload
              />
            </div>
          )}
          <div
            className={classnames(`${namespace}__item-description`, {
              [`${namespace}__item-description--with-actions`]: action,
              [`${namespace}__item-description--with-discount`]: discount,
            })}
          >
            {name && (
              <Action
                label={name.text}
                target={permalink}
                onClick={handleClick}
                className={`${namespace}__item-title`}
              />
            )}
            {current_value && (
              <MoneyAmountCombo
                previousValue={modelMoneyAmountCombo(original_price, 12)}
                currentValue={modelMoneyAmountCombo(current_value, 18)}
                discount={discount && { ...discount, size: 12 }}
                className={`${namespace}__price`}
              />
            )}
            {shipping && (
              <div className={`${namespace}__container-shipping-free`}>
                <StyledLabel
                  as="span"
                  text={interpolator(shipping.text, shipping.values, null, accessibilityText)}
                  {...shipping.styles}
                >
                  {shipping.text && shipping.subText && shipping?.styles?.font_size && (
                    <span className={`${namespace}__subtext font-size--${shipping.styles.font_size}`}>
                      {shipping.subText}
                    </span>
                  )}
                </StyledLabel>
              </div>
            )}
            {!hasDescriptionComponents && (
              <>
                {tag ? (
                  <div className={`${namespace}__container-tag`}>
                    <StyledLabel as="span" text={interpolator(tag.text, tag.values)} {...tag.styles} />
                  </div>
                ) : attributes && <span className={`${namespace}__item-attributes`}>{attributes.text}</span>}
              </>
            )}
          </div>
        </>)}
      {action && <StyledLabel
        as="a"
        href={permalink}
        {...action.label}
        accessibilityText={header?.text}
        className={classnames(`${namespace}__buy-now`, {
          [`${namespace}__buy-now--with-image`]: items_images,
          [`${namespace}__buy-now--without-original-price`]: !original_price,
          [`${namespace}__item-description--without-discount`]: !discount,
        })}
      />}
    </AndesCard>
  );
};

CardItem.propTypes = {
  card: shape({
    permalink: string,
    header: shape({
      text: string,
    }),
    name: shape({
      text: string,
    }),
    picture: shape({
      src: string,
    }),
    lazyLoad: bool,
    shipping: shape({
      text: string,
      values: shape({
        icon: shape({
          id: string,
          type: string,
          styles: shape({
            fill: string,
          }),
        }),
      }),
      styles: shape({
        font_size: string,
        color: string,
        font_family: string,
      }),
    }),
    tag: shape({
      text: string,
      values: shape({
        icon: shape({
          id: string,
          type: string,
          styles: shape({
            fill: string,
          }),
        }),
      }),
      styles: shape({
        font_size: string,
        color: string,
        font_family: string,
      }),
    }),
    attributes: shape({
      text: string,
    }),
    price: shape({
      original_price: shape({
        value: object,
        cents_type: string,
        currency_id: string,
        currency_symbol: string,
      }),
      current_value: shape({
        value: object,
        cents_type: string,
        currency_id: string,
        currency_symbol: string,
        suffix: string,
      }),
      discount: shape({
        value: number,
      }),
    }),
    accessibility_text: string,
    items_images: arrayOf(shape({
      picture: shape({
        src: string,
        alt: string,
        id: string,
      }),
    })),
    action: shape({
      label: shape({
        color: string,
        font_family: string,
        font_size: string,
        bg_color: string,
        text: string,
      }),
      target: string,
    }),
  }),
  track: object,
  sizeCard: string,
  additional_items: string,
};

export default withTracker(withTrackerOnPrint(CardItem));
