import React from 'react';
import classnames from 'classnames';
import { shape, string, bool, object, number } from 'prop-types';
import { Image } from 'nordic/image';
import { Card as AndesCard } from '@andes/card';
import { ProgressIndicatorLinear } from '@andes/progress-indicator-linear';

import withTracker from '../../../with-tracker';
import withTrackerOnPrint from '../../../with-tracker-on-print';
import { trackEvent } from '../../../../../services/tracking';
import { LAZYLOAD_OFF, LAZYLOAD_ON } from '../../../../../../utils/constants';
import Action from '../../../action';

const namespace = 'dynamic-access-card-media-item';
const iconPlaySrc = 'play_icon.svg';

const CardMedia = ({ card, track, sizeCard, index }) => {
  const {
    header,
    picture,
    permalink,
    description,
    elapsed_percentage,
    remaining_time,
    title,
    icon,
  } = card;

  const handleClick = () => {
    trackEvent(track);
  };

  return (
    <AndesCard
      className={classnames(
        'dynamic-access-card',
        `dynamic-access-card__${sizeCard}`,
        namespace,
      )}
    >
      {header && <h2 className={`${namespace}__title`}>{header.text}</h2>}
      {picture && picture.url && (
        <div className={`${namespace}__image`} aria-hidden="true">
          <Image
            src={picture.url.src}
            width="100%"
            height="100%"
            lazyload={index < 6 ? LAZYLOAD_OFF : LAZYLOAD_ON}
            preload={index < 6}
          />
          <div
            className={`${namespace}__remaining-info ${namespace}__remaining-info--gradient`}
          >
            {icon && (
              <div className="remaining-icon">
                <Image
                  src={iconPlaySrc}
                  width="100%"
                  height="100%"
                  lazyload={index < 6 ? LAZYLOAD_OFF : LAZYLOAD_ON}
                  preload={index < 6}
                />
              </div>
            )}
            {remaining_time && (
              <div className="remaining-time">
                <span> {remaining_time.text} </span>
              </div>
            )}
          </div>
          <ProgressIndicatorLinear
            className={`${namespace}__progress`}
            value={elapsed_percentage}
            size="small"
          />
        </div>
      )}

      {description && title && (
        <div className={`${namespace}__info`}>
          <div className={`${namespace}__description-content`}>
            <Action
              label={title.text}
              target={permalink}
              onClick={handleClick}
              className="description-content__title-name"
            />
          </div>

          <div className={`${namespace}__description`}>
            <span className="description"> {description.text}</span>
          </div>
        </div>
      )}
    </AndesCard>
  );
};

CardMedia.propTypes = {
  card: shape({
    permalink: string,
    icon: shape({
      id: string,
    }),
    remaining_time: shape({
      text: string,
    }),
    header: shape({
      text: string,
    }),
    picture: shape({
      url: shape({
        src: string,
      }),
    }),
    description: shape({
      text: string,
    }),
    title: shape({
      text: string,
    }),
    elapsed_percentage: number,
    lazyLoad: bool,
  }),
  track: object,
  sizeCard: string,
};

export default withTracker(withTrackerOnPrint(CardMedia));
