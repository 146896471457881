import React from 'react';
import { string, object, bool } from 'prop-types';
import { Button } from '@andes/button';

import Action from '../../action';

const customAction = ({
  cardName,
  target,
  label,
  isCustomCard,
  message,
  actionProps,
  accesibilityText,
}) => {
  const createMessage = () => {
    if (window && message) {
      window.freya.emit(message);
    }
  };
  if (isCustomCard) {
    return (
      <Button
        hierarchy="transparent"
        size="small"
        fullWidth
        label={label}
        className={`${cardName}__action`}
        onClick={createMessage}
      >
        {label}
        {accesibilityText && <span className="andes-visually-hidden">{accesibilityText}</span>}
      </Button>
    );
  }
  return (
    <Action
      label={label}
      target={target}
      accesibilityText={accesibilityText}
      {...actionProps}
    />
  );
};

Action.propTypes = {
  cardName: string,
  modal: string,
  target: string,
  label: object,
  isCustomCard: bool,
  message: string,
  accesibilityText: string,
};

export default customAction;
